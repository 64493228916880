<template>
  <div>
    <b-modal
      v-if="filterOrders.length != 0"
      id="modal-select3"
      title="Billing"
      centered
      ok-only
      size="sm"
    >
      <div v-if="loading == true">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left: 150px"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div>

      <b-card v-if="loading == false">
        <b-row>
          <b-col cols="8">
            <h5>Mode</h5>
          </b-col>
          <b-col>
            <p>{{ items[orderIndex].modeOfPayment }}</p>
          </b-col>
        </b-row>

        <b-row
          class="mt-2"
          v-for="item in items[orderIndex].orderDetailsPurchase"
          v-bind:key="item.id"
        >
          <b-col cols="8">
            <h5>{{ item.name }}</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + item.netamount }}</p>
          </b-col>
        </b-row>
        <b-row
          class="mb-2"
          v-for="item in items[orderIndex].orderDetailsFitting"
          v-bind:key="item.id"
        >
          <b-col cols="8">
            <h5>
              {{ item.name }} <span><small>(Fitting room) </small> </span>
            </h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + item.netamount }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <h5>Value Added Tax</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[orderIndex].vat }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <h5>Delivery Charges</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[orderIndex].deliveryCharges }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <h5>Discount</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[orderIndex].discount }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <h5>Bill</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[orderIndex].total }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <h5>Net Bill</h5>
          </b-col>
          <b-col>
            <p>{{ 'AED ' + items[orderIndex].netTotal }}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <!-- select 2 demo -->
    <b-modal
      v-if="filterOrders.length != 0"
      id="modal-select2"
      title="Order Details"
      centered
      ok-only
      size="md"
    >
      <b-tabs
        v-if="items[orderIndex].orderDetailsPurchase.length !== 0"
        v-model="tabIndex"
        content-class="pt-1"
        fill
      >
        <b-tab title="Purchases">
          <b-tabs>
            <b-tab
              v-for="item in items[orderIndex].orderDetailsPurchase"
              v-bind:key="item.id"
              :title="item.name"
            >
              <b-card>
                <b-row>
                  <b-col cols="5">
                    <b-card-img
                      :src="item.cover"
                      style="height: 140px; width: 140px"
                    >
                    </b-card-img>
                  </b-col>

                  <b-col cols="6">
                    <b-card-text> Order ID: {{ item.orderId }} </b-card-text>
                    <br />
                    <h3>
                      {{ item.name }}
                    </h3>
                    <b-card-text>
                      {{ item.category + ' -> ' + item.subcategory }}
                    </b-card-text>
                    <p><b>Cost: </b>AED {{ item.netamount }}</p>
                    <p><b>Qty: </b> {{ item.qty }}</p>

                    <p>
                      <b>Sizes: </b>
                      <b-badge
                        style="margin-inline: 2px"
                        v-for="i in item.size.split(',')"
                        :key="i"
                        variant="primary"
                      >
                        {{ i }}
                      </b-badge>
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-tab>

        <!-- <b-card>
          <b-row>
            <b-col> 
          <b-card-text>
            <b>Name: </b> {{items[0].firstName}}
          </b-card-text>
            </b-col>

             <b-col> 
          <b-card-text>
            <b>Contact: </b> {{items[0].phoneNo}}
          </b-card-text>
            </b-col>
          </b-row>
         
                <b-row class="mt-1">
            <b-col> 
          <b-card-text>
            <b>Date: </b> {{items[0].date}}
          </b-card-text>
            </b-col>

          </b-row>

            <b-row class="mt-1">
             <b-col> 
          <b-card-text>
            <b>Address: </b> {{items[0].address}}
          </b-card-text>
            </b-col>
          </b-row>
        </b-card> -->
      </b-tabs>
      <b-tabs
        v-if="items[orderIndex].orderDetailsFitting.length !== 0"
        v-model="tabIndex"
        content-class="pt-1"
        fill
      >
        <b-tab title="Fitting Room">
          <b-tabs>
            <b-tab
              v-for="item in items[orderIndex].orderDetailsFitting"
              v-bind:key="item.id"
              :title="item.name"
            >
              <b-card>
                <b-row>
                  <b-col cols="5">
                    <b-card-img
                      :src="item.cover"
                      style="height: 140px; width: 140px"
                    >
                    </b-card-img>
                  </b-col>

                  <b-col cols="6">
                    <b-card-text> Order ID: {{ item.orderId }} </b-card-text>
                    <br />
                    <h3>
                      {{ item.name }}
                    </h3>
                    <b-card-text>
                      {{ item.category + ' -> ' + item.subcategory }}
                    </b-card-text>

                    <p><b>Cost: </b> AED {{ item.netamount }}</p>
                    <p><b>Qty: </b> {{ item.qty }}</p>

                    <p>
                      <b>Sizes: </b>
                      <b-badge
                        style="margin-inline: 2px"
                        v-for="i in item.size.split(',')"
                        :key="i"
                        variant="primary"
                      >
                        {{ i }}
                      </b-badge>
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
      <b-row class="text-center">
        <b-col>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            v-if="mystatus === 'Ready to deliver'"
            @click="Quiqup(detailObj)"
          >
            <feather-icon icon="TruckIcon" class="mr-50" />
            <span class="align-middle">Call Quiqup</span>
          </b-button> -->

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            v-if="detailObj.quiqupOrderID == null"
            @click="OrderStatus(detailObj, 'Ready to deliver')"
          >
            Ready to deliver
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            class="ml-1"
            v-if="detailObj.quiqupOrderID != null"
            @click="PrintLabel(detailObj)"
          >
            <feather-icon icon="TruckIcon" class="mr-50" />
            <span class="align-middle">Print Label</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="ml-1"
            v-if="mystatus === 'On going'"
            @click="OrderStatus(detailObj.id, 'Canceled')"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      @ok="filterData()"
      centered
      title="Filters"
    >
      <b-form>
        <b-form-group>
          <h5>Select date range</h5>
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{ mode: 'range' }"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-start">
              <!-- <div style="padding-right:15px">
                <b-button v-b-modal.modal-login variant="primary">
                  <span class="text-nowrap">Filters</span>
                </b-button>
              </div> -->
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
        <div class="mt-1 mr-1" style="margin-bottom: -10px">
          <b-row align-h="end">
            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="warning" @click="GetOrders('On going')">
                  <span class="text-nowrap"
                    >On going
                    <b-badge variant="primary" class="badge-glow">
                      {{ ongoing }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>

            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button
                  variant="success"
                  @click="GetOrders('Ready to deliver')"
                >
                  <span style="padding-left: 10px" class="text-nowrap">
                    Ready to deliver
                    <b-badge variant="primary" class="badge-glow">
                      {{ readytodeliver }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>

            <b-col class="pr-1" cols="*" lg="*" md="*" sm="*">
              <div>
                <b-button variant="danger" @click="GetOrders('Cancel')">
                  <span class="text-nowrap">
                    Cancel
                    <b-badge variant="primary" class="badge-glow">
                      {{ cancel }}
                    </b-badge></span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div>
        <b-table
          responsive
          striped
          :items="filterOrders"
          :fields="column"
          :tbody-tr-class="table - danger"
        >
          <template #cell(status)="data">
            <b-badge pill :variant="data.value.variant">
              {{ data.value.status }}
            </b-badge>
          </template>

          <template #cell(netTotal)="data">
            AED{{ ' ' + data.item.netTotal }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-select2
                @click="openDetails(data.index)"
              >
                <feather-icon icon="FileTextIcon" />

                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-select3
                @click="openDetails(data.index)"
              >
                <feather-icon icon="CreditCardIcon" />
                <span class="align-middle ml-50">Billing</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'On going'"
                @click="OrderStatus(data.item.id, 'Ready to deliver')"
              >
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Ready to deliver</span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'On going'"
                @click="OrderStatus(data.item.id, 'Cancel')"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel </span>
              </b-dropdown-item> -->

              <!-- 
              <b-dropdown-item
                v-if="data.item.status.status === 'Under progress'"
                @click="OrderStatus(data.item.orderId, 'Cancel')"
              >
                <feather-icon icon="DeleteIcon" />
                <span class="align-middle ml-50">Cancel </span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item
                v-if="data.item.status.status === 'Under progress'"
                @click="OrderStatus(data.item.orderId, 'Ready for delivery')"
              >
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Ready </span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item 
                v-if="data.item.status.status === 'Ready for delivery' || data.item.status.status === 'Cancel' || data.item.status.status === 'Reject'"
                @click="OrderStatus(data.item.orderId, 'Archive')"
              >
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive </span>
              </b-dropdown-item> -->

              <!-- <b-dropdown-item @click="DenyOrder(data.item.orderId)">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Reject</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>
        <b-row v-if="filterOrders.length == 0">
          <b-col cols="12" class="demo-spacing-0">
            <b-alert variant="primary" show>
              <div class="alert-body">
                <span
                  ><strong>Empty!</strong> There is no record to display.</span
                >
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  BTabs,
  BTab,
  //BButtonGroup,
  BSpinner,
  BCardImg,
  BAlert,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useOrdersList from './useOrdersList';
import orderStoreModule from '../orderStoreModule';
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
import axios from 'axios';
//import Multiselect from "vue-multiselect";
export default {
  components: {
    //Multiselect,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BCardImg,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    BBadge,
    //BButtonGroup,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BPagination,
    //vSelect,
    flatPickr,
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    console.log(this.$store.state.userData.type);

    this.LoadData();
  },
  computed: {
    filterOrders: function() {
      return this.items.filter(pro => {
        return (
          pro.firstName.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.id.toString().match(this.searchQuery.toString())
        );
      });
    },
  },
  data() {
    return {
      mystatus: '',
      displayObj: {},
      orderIndex: 0,
      orderTabs: ['Purchases', 'Fitting Room'],
      uaeTime: '',
      readytodeliver: '',
      cancel: '',
      ongoing: '',
      date: '',
      searchQuery: '',
      loading: false,
      CakeDetails: [],
      tabIndex: 1,
      cover: '',
      column: [
        {
          label: 'OrderId',
          key: 'id',
          isRowHeader: true,
        },
        { label: 'Customer', key: 'firstName' },
        'date',
        { label: 'Net Total', key: 'netTotal' },
        'status',
        'actions',
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        orderId: 0,
        userId: 0,
        bill: 0,
        vat: 0,
        discount: 0,
        modeOfpayment: '',
        vendorId: 0,
        deliveryCharges: 0,
        date: '',
        netBill: 0,
        totalBill: 0,
        transactionId: '',
        paidAmount: 0,
      },

      detailObj: {},
    };
  },
  methods: {
    Quiqup(id) {
      var d = new Date();
      var localTime = d.getTime();
      var localOffset = d.getTimezoneOffset() * 60000;
      var utc = localTime + localOffset;
      var offset = 4; //UTC of Dubai is +04.00
      var dubai = utc + 3600000 * offset;
      var nd = new Date(dubai);
      var hour = nd.getHours();

      console.log('## H ###', hour);

      if (hour < 9) {
        alert('Please call delivery between 9:00 AM to 6:00 PM');
        return;
      } else if (hour >= 18) {
        alert('Please call delivery between 9:00 AM to 6:00 PM');
        return;
      } else {
        if (id.vendor.latitude == null || id.vendor.longitude == null) {
          Swal.fire({
            title: 'Location not selected?',
            text: 'Please select location of your business',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'apps-address' });
            }
          });
        } else {
          var quiqupBody = {
            kind: 'partner_next_day',
            notes: '',
            payment_amount: 0,
            partner_order_id: id.id,
            origin: {
              contact_name: id.vendor.business,
              contact_phone: id.vendorContact,
              partner_order_id: id.id,
              notes: '',
              address: {
                address1: id.vendor.address,
                address2: '',
                coords: [id.vendor.latitude, id.vendor.longitude],
                country: 'UAE',
                town: id.vendor.city,
              },
            },
            destination: {
              contact_name: id.firstName + ' ' + id.lastName,
              contact_phone: id.phoneNo,
              notes: '',
              address: {
                address1: id.address,
                address2: '',
                coords: [id.latitude, id.longitude],
                country: 'UAE',
                town: '',
              },
            },
            items: id.items,
          };

          console.log(quiqupBody);
          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://dc.appick.io/brandawayQuiqup',
            data: quiqupBody,
          };

          axios(config)
            .then(response => {
              if (response.data.status == 'success') {
                var obj = {
                  orderId: id.id,
                  pickupTrackingURL: response.data.response.pickupTrackingURL,
                  dropoffTrackingURL: response.data.response.dropoffTrackingURL,
                  quiqupOrderID: response.data.response.quiqupOrderID + '',
                  barcode: response.data.response.barcode + '',
                };
                var config2 = {
                  method: 'post',
                  url: 'https://brandaway.fastech.pk/api/Orders/CallQuiqup',
                  data: obj,
                  headers: {
                    Authorization: 'bearer ' + this.$store.state.userData.token,
                  },
                };

                axios(config2)
                  .then(response => {
                    if (response.data.status == 'success') {
                      Swal.fire('Quiqup Called' + '!', '', 'success').then(
                        res => {
                          this.LoadData();
                          this.$bvModal.hide('modal-select2');
                        },
                      );
                    }
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
                console.log(response.data.response);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },
    PrintLabel(row) {
      console.log(row);
      axios.get('https://dc.appick.io' + '/brandawayQuiqup').then(res => {
        console.log('token', res.data);
        var url = 'https://api-ae.quiqup.com/order_label/' + row.quiqupOrderID;
        console.log('url', url);

        const headers = {
          'Content-Type': 'application/json',
          Authorization: res.data,
        };

        axios(`${url}`, {
          method: 'GET',
          responseType: 'blob', //Force to receive data in a Blob Format
          headers: {
            Authorization: res.data,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods':
              'HEAD, GET, POST, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers':
              'Origin, Content-Type, X-Auth-Token',
          },
        })
          .then(response => {
            //Create a Blob from the PDF Stream
            console.log('Resp', response);
            const file = new Blob([response.data], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          })
          .catch(error => {
            console.log('Error', error);
            this.$notify({
              verticalAlign: 'bottom',
              horizontalAlign: 'center',
              message: 'Error! <br />No label found.',
              type: 'danger',
              icon: 'fa fa-error',
              timeout: this.$store.state.timeout,
            });
          });
      });
    },
    GetOrders(status) {
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Orders/GetByOrderStatus/' +
          this.$store.state.userData.userID +
          '/' +
          status,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then(response => {
          this.readytodeliver = response.data.readytodeliver;
          this.cancel = response.data.cancel;
          this.ongoing = response.data.ongoing;

          // this.items =  response.data.data;
          this.items = [];
          response.data.data.forEach(elem => {
            if (elem.status == 'On going') {
              elem.status = { status: 'On going', variant: 'warning' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Cancel') {
              elem.status = { status: 'Cancel', variant: 'danger' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Ready to deliver') {
              elem.status = { status: 'Ready to deliver', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    rowClass(item, type) {
      const colorClass = 'table-danger';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.date < this.uaeTime && item.status.status == 'Under progress') {
        return colorClass;
      }
      if (
        item.date < this.uaeTime &&
        item.status.status == 'Requires actions'
      ) {
        return colorClass;
      }
    },
    filterData() {
      console.log('Hello');
      let from = this.date.split(' ')[0];
      let to = this.date.split(' ')[2];
      console.log(from, to);
      if (from == undefined || to == undefined) {
        return this.LoadData();
      }
      //this.loading = true;
      // data
      // this.$http.get('/ecommerce/data')
      //   .then(response => {
      //     this.data = response.data

      //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
      //     // ? This is just for demo purpose
      //     const userData = getUserData()
      //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      //   })
      var axios = require('axios');

      var config = {
        method: 'get',
        url:
          'https://okaaik.fastech.pk/api/oOrders/getoOrdersbyfilter/' +
          this.$store.state.userData.userID +
          '/?fromdate=' +
          from +
          '&todate=' +
          to,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log( response.data);
          if (response.data.status === 'success') {
            console.log('mydata', response.data);
            this.items = [];
            response.data.query.forEach(elem => {
              elem.date = elem.date.split('T')[0];
              this.items.push(elem);
            });

            //this.data = response.data;
          }

          //   this.myProducts = response.data.data;
          //    console.log(this.myProducts);
          // if(this.myProducts.data.length == 0)
          //  {
          //    this.productEmpty = true;
          //  }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // Billing(item) {
    //   console.log(item);
    //   this.loading = true;
    //   var axios = require('axios');

    //   var config = {
    //     method: 'get',
    //     url:
    //       'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' +
    //       item.orderId,
    //     headers: {
    //       Authorization:
    //         'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
    //     },
    //   };

    //   axios(config)
    //     .then(response => {
    //       // console.log(JSON.stringify(response.data));

    //       //  this.data  =  response.data;
    //       //console.log(response.data.orderDetails);
    //       this.CakeDetails = [];
    //       //this.product = response.data.orderDetails;
    //       this.CakeDetails = response.data.orderDetails;
    //       console.log(this.CakeDetails);
    //       this.myObj.orderId = item.orderId;
    //       this.myObj.userId = item.userId;
    //       this.myObj.vendorId = item.vendorId;
    //       this.myObj.modeOfpayment = item.modeOfpayment;
    //       this.myObj.bill = item.bill;
    //       this.myObj.vat = item.vat;
    //       this.myObj.deliveryCharges = item.deliveryCharges;
    //       this.myObj.totalBill = item.totalBill;
    //       this.myObj.discount = item.discount;
    //       this.myObj.netBill = item.netBill;
    //       this.myObj.transactionId = item.transactionId;
    //       this.myObj.paidAmount = item.paidAmount;
    //       this.loading = false;
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
    openDetails(id) {
      this.orderIndex = id;
      this.detailObj = this.items[this.orderIndex];
      this.mystatus = this.detailObj.status.status;
      return console.log(id, this.detailObj, '========', this.mystatus);
      this.loading = true;
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/oOrders/Detailsbyorders/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          console.log(response.data.orderDetails);
          this.CakeDetails = [];
          //this.product = response.data.orderDetails;
          response.data.orderDetails.forEach(elem => {
            elem.date = elem.date.split('T')[0];
            this.CakeDetails.push(elem);
          });
          console.log(this.CakeDetails);

          this.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    OrderStatus(id, status) {
      var myID = id.id;
      // return console.log(id);
      Swal.fire({
        title: 'Ready to call the delivery partner?',
        text: "You won't be able to revert this!",
        icon: 'question',
        iconColor: '#FB595f',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#FB595f',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://brandaway.fastech.pk/api/Orders/OrderStatus/' +
              myID +
              '/' +
              status,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(status + '!', '', 'success').then(res => {
                  this.Quiqup(id);
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DenyOrder(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/oOrders/UpdateOrderStatus/?id=' +
              id +
              '&check=false',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Rejected!',
                  'the order has been rejected.',
                  'success',
                ).then(res => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      var axios = require('axios');

      var screen = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Orders/VendorOrders/' +
          this.$store.state.userData.userID,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then(response => {
          console.log(response.data);
          console.log('Helloo List', response.data);
          this.readytodeliver = response.data.readytodeliver;
          this.cancel = response.data.cancel;
          this.ongoing = response.data.ongoing;

          // this.items =  response.data.data;
          this.items = [];
          response.data.data.forEach(elem => {
            if (elem.status == 'On going') {
              elem.status = { status: 'On going', variant: 'warning' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Cancel') {
              elem.status = { status: 'Cancel', variant: 'danger' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
            if (elem.status == 'Ready to deliver') {
              elem.status = { status: 'Ready to deliver', variant: 'success' };
              var d = new Date(elem.date);
              elem.date = d.toDateString();
              this.items.push(elem);
            }
          });
          //  this.rows = response.data.data;

          //console.log(this.myProducts);
          console.log('HeItemssslloo ', this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
